import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import SearchResult from "../SearchResult";

const SearchResults = ({
  togglePropertyModal,
  query = "",
  criteria = {},
  isMobile,
}) => {
  const [searches, setSearches] = useState([]);
  const [properties, setProperties] = useState([]);

  // Fetch properties from Firebase
  useEffect(() => {
    const fetchProperties = async () => {
      console.log("Starting to fetch properties...");
      try {
        const propertiesCollection = collection(db, "properties");
        const propertiesSnapshot = await getDocs(propertiesCollection);
        const propertiesList = propertiesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setProperties(propertiesList);

        // Create initial search with all properties
        const initialSearch = {
          properties: propertiesList,
          keywords: ["All Properties"],
          query: "",
          criteria: {},
          identifier: `search-${Date.now()}`,
          timestamp: Date.now(),
          hidden: false,
        };

        setSearches([initialSearch]);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };

    fetchProperties();
  }, []);

  // Update searches when criteria or query changes
  useEffect(() => {
    if (properties.length > 0 && (Object.keys(criteria).length > 0 || query)) {
      const filteredProperties = properties.filter((property) => {
        // Price Range
        if (criteria.priceRange) {
          const { min, max } = criteria.priceRange;
          if (min && property.price < min) return false;
          if (max && property.price > max) return false;
        }

        // Bedrooms
        if (criteria.bedrooms) {
          const { min, max } = criteria.bedrooms;
          if (min && property.beds < min) return false;
          if (max && property.beds > max) return false;
        }

        // Bathrooms
        if (criteria.bathrooms) {
          const { min, max } = criteria.bathrooms;
          if (min && property.baths < min) return false;
          if (max && property.baths > max) return false;
        }

        return true;
      });

      const keywords = [];

      if (criteria.priceRange) {
        const { min, max } = criteria.priceRange;
        if (min && max) {
          keywords.push(`£${min.toLocaleString()} - £${max.toLocaleString()}`);
        } else if (min) {
          keywords.push(`Min £${min.toLocaleString()}`);
        } else if (max) {
          keywords.push(`Up to £${max.toLocaleString()}`);
        }
      }

      if (criteria.bedrooms) {
        const { min, max } = criteria.bedrooms;
        if (min && max) {
          keywords.push(`${min}-${max} Bedrooms`);
        } else if (min) {
          keywords.push(`${min}+ Bedrooms`);
        } else if (max) {
          keywords.push(`Up to ${max} Bedrooms`);
        }
      }

      const newSearch = {
        properties: filteredProperties,
        keywords,
        query,
        criteria,
        identifier: `search-${Date.now()}`,
        timestamp: Date.now(),
        hidden: false,
      };

      setSearches((prev) => {
        const updatedSearches = prev.map((search) => ({
          ...search,
          hidden: true,
        }));
        return [...updatedSearches, newSearch];
      });
    }
  }, [properties, criteria, query]);

  return (
    <div>
      {searches.map((search) => (
        <SearchResult
          key={search.identifier}
          id={search.identifier}
          keywords={search.keywords}
          properties={search.properties}
          query={search.query}
          hidden={search.hidden}
          togglePropertyModal={togglePropertyModal}
          className="transition-all duration-300 ease-in-out"
          isMobile={isMobile}
        />
      ))}
      {searches.length === 0 && (
        <div className="text-center p-4">Loading properties...</div>
      )}
    </div>
  );
};

export default SearchResults;

// import React, { useEffect, useState } from "react";
// import SearchResult from "../SearchResult";

// const SearchResults = ({
//   properties = [],
//   togglePropertyModal,
//   query = "",
//   criteria = {},
// }) => {
//   const [searches, setSearches] = useState([]);

//   useEffect(() => {
//     const handleBeforeUnload = () => {
//       setSearches([]);
//     };

//     window.addEventListener("beforeunload", handleBeforeUnload);
//     return () => window.removeEventListener("beforeunload", handleBeforeUnload);
//   }, []);

//   const scrollIntoNewSearch = (identifier) => {
//     const element = document.getElementById(identifier);
//     if (element) {
//       element.scrollIntoView({ behavior: "smooth", block: "start" });
//     }
//   };

//   const formatPrice = (value) => {
//     if (!value) return "";
//     return value >= 1000000
//       ? `$${(value / 1000000).toFixed(1)}M`
//       : `$${value.toLocaleString()}`;
//   };

//   const formatArea = (value) => {
//     if (!value) return "";
//     return value >= 10000
//       ? `${Math.round(value / 1000)}k sqft`
//       : `${value.toLocaleString()} sqft`;
//   };

//   // Add new search with strict duplicate checking and hide previous searches
//   useEffect(() => {
//     if (properties.length > 0 && criteria && query) {
//       const generatedKeywords = [];

//       // Price Range
//       if (criteria.priceRange) {
//         const { min, max } = criteria.priceRange;
//         if (min && max) {
//           generatedKeywords.push(`${formatPrice(min)} to ${formatPrice(max)}`);
//         } else if (min) {
//           generatedKeywords.push(`Min ${formatPrice(min)}`);
//         } else if (max) {
//           generatedKeywords.push(`Up to ${formatPrice(max)}`);
//         }
//       }

//       // Bedrooms
//       if (criteria.bedrooms) {
//         const { min, max } = criteria.bedrooms;
//         const maxBedrooms = 10;
//         if (min && max) {
//           if (min === max) {
//             generatedKeywords.push(
//               `${Math.min(min, maxBedrooms)} ${
//                 min === 1 ? "Bedroom" : "Bedrooms"
//               }`
//             );
//           } else {
//             generatedKeywords.push(
//               `${Math.min(min, maxBedrooms)} to ${Math.min(
//                 max,
//                 maxBedrooms
//               )} Bedrooms`
//             );
//           }
//         } else if (min) {
//           generatedKeywords.push(`${Math.min(min, maxBedrooms)}+ Bedrooms`);
//         } else if (max) {
//           generatedKeywords.push(
//             `Up to ${Math.min(max, maxBedrooms)} Bedrooms`
//           );
//         }
//       }

//       // Bathrooms
//       if (criteria.bathrooms) {
//         const { min, max } = criteria.bathrooms;
//         const maxBathrooms = 8;
//         if (min && max) {
//           if (min === max) {
//             generatedKeywords.push(
//               `${Math.min(min, maxBathrooms)} ${
//                 min === 1 ? "Bathroom" : "Bathrooms"
//               }`
//             );
//           } else {
//             generatedKeywords.push(
//               `${Math.min(min, maxBathrooms)} to ${Math.min(
//                 max,
//                 maxBathrooms
//               )} Bathrooms`
//             );
//           }
//         } else if (min) {
//           generatedKeywords.push(`${Math.min(min, maxBathrooms)}+ Bathrooms`);
//         } else if (max) {
//           generatedKeywords.push(
//             `Up to ${Math.min(max, maxBathrooms)} Bathrooms`
//           );
//         }
//       }

//       // Living Area
//       if (criteria.livingAreaValue) {
//         const { min, max } = criteria.livingAreaValue;
//         if (min && max) {
//           if (min === max) {
//             generatedKeywords.push(formatArea(min));
//           } else {
//             generatedKeywords.push(`${formatArea(min)} to ${formatArea(max)}`);
//           }
//         } else if (min) {
//           generatedKeywords.push(`Min ${formatArea(min)}`);
//         } else if (max) {
//           generatedKeywords.push(`Up to ${formatArea(max)}`);
//         }
//       }

//       // Home Type
//       if (criteria.homeType) {
//         const homeType = criteria.homeType
//           .split("_")
//           .map(
//             (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
//           )
//           .join(" ");
//         generatedKeywords.push(homeType);
//       }

//       // Neighborhoods
//       if (criteria.neighborhoods?.length > 0) {
//         generatedKeywords.push(
//           `Neighborhoods: ${criteria.neighborhoods.join(", ")}`
//         );
//       }

//       // Amenities
//       if (criteria.amenities?.length > 0) {
//         generatedKeywords.push(`Amenities: ${criteria.amenities.join(", ")}`);
//       }

//       // Zip Codes
//       if (criteria.zipCodes?.length > 0) {
//         generatedKeywords.push(`Zip Codes: ${criteria.zipCodes.join(", ")}`);
//       }

//       const searchKey = JSON.stringify({ query, criteria });

//       setSearches((prevSearches) => {
//         const searchExists = prevSearches.some(
//           (search) =>
//             JSON.stringify({
//               query: search.query,
//               criteria: search.criteria,
//             }) === searchKey
//         );

//         if (searchExists) {
//           return prevSearches;
//         }

//         // Hide all previous searches
//         const updatedSearches = prevSearches.map((search) => ({
//           ...search,
//           hidden: true,
//         }));

//         // Add new search
//         const newSearch = {
//           properties,
//           keywords: generatedKeywords,
//           query,
//           criteria,
//           identifier: `search-${Date.now()}`,
//           timestamp: Date.now(),
//           hidden: false, // New search is visible
//         };

//         return [...updatedSearches, newSearch];
//       });
//     }
//   }, [properties, criteria, query]);

//   useEffect(() => {
//     if (searches.length > 0) {
//       const latestSearch = searches[searches.length - 1];
//       scrollIntoNewSearch(latestSearch.identifier);
//     }
//   }, [searches.length]);

//   return (
//     <div className="space-y-4">
//       {searches.map((search) => (
//         <SearchResult
//           key={search.identifier}
//           id={search.identifier}
//           keywords={search.keywords}
//           properties={search.properties}
//           query={search.query}
//           hidden={search.hidden}
//           togglePropertyModal={togglePropertyModal}
//           className="transition-all duration-300 ease-in-out"
//         />
//       ))}
//     </div>
//   );
// };

// export default SearchResults;
