import React, { useRef, useEffect } from "react";

import ModalHeader from "../../ModalComponents/ModalHeader";
import TimeAndSocial from "../../ModalComponents/TimeAndSocial";
import PropertySummary from "../../ModalComponents/PropertySummary";
import AgentInfo from "../../ModalComponents/AgentInfo";
import PhotoCarrousel from "../../ModalComponents/PhotoCarrousel";
import AvailabilityAndPlan from "../../ModalComponents/AvailabilityAndPlan";
import DescriptionSection from "../../ModalComponents/DescriptionSection";
import PlaceCaracteristics from "../../ModalComponents/PlaceCaracteristics";
import LocalInfo from "../../ModalComponents/LocalInfo";
import CopilotMargin from "../../ModalComponents/CopilotMargin";
import PriceInfo from "../../ModalComponents/PriceInfo";

const Modal = ({ isOpen, onClose, propertyInfo = {}, isMobile }) => {
  const scrollableRef = useRef(null);
  const overviewRef = useRef(null);
  const picturesRef = useRef(null);
  const floorplanRef = useRef(null);
  const propertyInfoRef = useRef(null);
  const locationRef = useRef(null);
  const ratingRef = useRef(null);

  const {
    address = {},
    amenities = {},
    baths = 0,
    beds = 0,
    receptions = 0,
    deposit = 0,
    description = "",
    floorplan = "",
    furnished = false,
    id = "",
    link = "",
    listed_date = "",
    min_lease_lenght = "",
    move_in_date = "",
    pictures = {},
    price = 0,
    schoold = [],
    sqft = "",
    transport = "",
    daysOnZillow = 0,
    coordinates = {},
  } = propertyInfo || {};

  const formattedPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Number(price));

  let formattedAddress = "";
  try {
    const parsedAddress = JSON.parse(address);
    formattedAddress = `${parsedAddress.streetAddress || ""}, ${
      parsedAddress.city || ""
    }, ${parsedAddress.state || ""} ${parsedAddress.zipcode || ""}`.trim();
    console.log("Formatted Address:", formattedAddress);
  } catch (error) {
    // If parsing fails, use the address as is (it might already be a string)
    formattedAddress = address.toString();
    console.log("Error parsing address, using as-is:", formattedAddress);
  }

  // If formattedAddress is empty, provide a default message
  formattedAddress = formattedAddress || "Address not available";

  // let agentDetails = {};
  // try {
  //   const attributionJSON = JSON.parse(attributionInfo);
  //   const listingProviderJSON = JSON.parse(listingProvider);

  //   agentDetails = {
  //     ...attributionJSON,
  //     ...listingProviderJSON,
  //   };
  // } catch (error) {
  //   agentDetails = { error: "No agent details" };
  // }

  // let location = {};
  // try {
  //   const extractLocation = JSON.parse(adTargets);

  //   location = {
  //     mlat: extractLocation.mlat,
  //     mlong: extractLocation.mlong,
  //   };
  // } catch (error) {
  //   location = { error: "No location" };
  // }

  useEffect(() => {
    if (isOpen && scrollableRef.current) {
      scrollableRef.current.scrollTo(0, 0); // Reset scroll position when modal opens
    }
  }, [isOpen]);

  const scrollToSection = (ref) => {
    if (ref.current && scrollableRef.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div
      className={`fixed inset-0 z-50 transition-transform duration-300 ease-in-out ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div
        className={`fixed right-0 h-full ${
          isMobile ? "w-full" : "w-[85vw]"
        } bg-white shadow-2xl transition-transform duration-200 rounded-l-lg border border-gray-100`}
      >
        <div className="h-full flex flex-col">
          <div
            className={`flex-1 ${
              isMobile ? "px-4 py-2" : "px-8 py-2"
            } overflow-y-auto`}
            ref={scrollableRef}
          >
            <ModalHeader
              onClose={onClose}
              scrollToSection={scrollToSection}
              overviewRef={overviewRef}
              picturesRef={picturesRef}
              floorplanRef={floorplanRef}
              propertyInfoRef={propertyInfoRef}
              locationRef={locationRef}
              ratingRef={ratingRef}
              isMobile={isMobile}
            />
            {isMobile ? (
              // Mobile view content
              <div>
                <TimeAndSocial daysPosted={listed_date} isMobile={isMobile} />
                <PropertySummary
                  formattedAddress={formattedAddress}
                  beds={beds}
                  baths={baths}
                  sqft={sqft}
                  price={formattedPrice}
                  isMobile={isMobile}
                />
                <PhotoCarrousel property={propertyInfo} isMobile={isMobile} />
                <AvailabilityAndPlan propertyInfo={propertyInfo} />
                <DescriptionSection description={description} />
                <PlaceCaracteristics propertyInfo={propertyInfo} />
                <LocalInfo location={propertyInfo.coordinates || {}} />
              </div>
            ) : (
              // Desktop view content
              <div>
                <div ref={overviewRef}>
                  <TimeAndSocial daysPosted={listed_date} />
                </div>
                <div ref={propertyInfoRef}>
                  <PropertySummary
                    formattedAddress={formattedAddress}
                    beds={beds}
                    baths={baths}
                    sqft={sqft}
                    price={formattedPrice}
                  />
                </div>
                <div ref={ratingRef}>
                  {/* <PriceInfo
                    // zestimate={zestimate}
                    // rentZestimate={rentZestimate}
                    price={price}
                  /> */}
                  {/* <AgentInfo agentDetails={agentDetails} /> */}
                </div>
                <div ref={picturesRef} className="flex">
                  <PhotoCarrousel property={propertyInfo} />
                  <CopilotMargin propertyInfo={propertyInfo} />
                </div>
                <div ref={floorplanRef}>
                  <AvailabilityAndPlan propertyInfo={propertyInfo} />
                </div>
                <div ref={locationRef}>
                  <DescriptionSection description={description} />
                </div>
                <div>
                  <PlaceCaracteristics propertyInfo={propertyInfo} />
                </div>
                <div>
                  <LocalInfo location={propertyInfo.coordinates || {}} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
