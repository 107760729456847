// CopilotMargin.jsx
import React, { useState } from "react";
import SearchShortcut from "./SearchShortcut";
import { CopilotBar } from "./CopilotBar";
import { ChatContainer } from "./ChatContainer";
import { Bot } from "lucide-react";

const CopilotMargin = ({ propertyInfo }) => {
  const [chatHistory, setChatHistory] = useState({
    messages: [
      {
        role: "assistant",
        content:
          "Hello! I'm your AI property assistant. I have all the details about this property and can answer any questions you might have about it. Feel free to ask about prices, features, neighborhood, or anything else you'd like to know!",
        isTyping: false, // Welcome message won't type out
      },
    ],
    contextId: Date.now().toString(),
  });

  const handleNewMessage = (message) => {
    setChatHistory((prev) => {
      const newMessages = [...prev.messages];

      if (message.replaceTyping) {
        const lastMessage = newMessages[newMessages.length - 1];
        if (lastMessage && lastMessage.isTyping) {
          newMessages[newMessages.length - 1] = {
            ...message,
            isTyping: true, // Make sure responses type out
          };
          return {
            ...prev,
            messages: newMessages,
          };
        }
      }

      // Add new message with typing for assistant responses
      return {
        ...prev,
        messages: [
          ...newMessages,
          {
            ...message,
            isTyping: message.role === "assistant" && !message.replaceTyping,
          },
        ],
      };
    });
  };

  return (
    <div className="flex flex-col w-1/3 mt-3">
      <div className="relative flex flex-col h-[36rem]">
        <div className="px-4 py-2">
          <div className="flex items-center gap-2 mb-2">
            <Bot className="w-5 h-5 text-blue-600" />
            <h3 className="text-sm font-semibold text-gray-800">
              Property Assistant
            </h3>
          </div>
          <div className="flex gap-2">
            <SearchShortcut text="Shops around?" />
            <SearchShortcut text="Compare property price" />
          </div>
        </div>

        <div className="flex-1 overflow-hidden">
          <div className="h-full overflow-y-auto px-4">
            <ChatContainer messages={chatHistory.messages} />
          </div>
        </div>
        <div className="px-4">
          <div className="bg-white border-t border-gray-100 py-2">
            <CopilotBar
              propertyInfo={propertyInfo}
              onNewMessage={handleNewMessage}
              chatHistory={chatHistory}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopilotMargin;
