import React from "react";
import { Download, Bookmark } from "lucide-react";

const ActionButton = ({ Text, Icon, onClick }) => {
  return (
    <div>
      <button
        className="text-black text-s bg-white rounded-lg border border-[#EBEBEB] px-2.5 py-1.5 flex items-center justify-center gap-1 hover:bg-slate-300"
        onClick={onClick}
      >
        {Icon && <Icon size={Text ? 16 : 22} />}
        {Text}
      </button>
    </div>
  );
};

export default ActionButton;
