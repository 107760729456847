import React, { useState, useCallback, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

const ModalMap = ({ location }) => {
  const [map, setMap] = useState(null);
  const [position, setPosition] = useState(null);
  const [center, setCenter] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAdTMHEaKj6jX1oerPrh4Qs-sPad49OAHc",
  });

  const mapContainerStyle = {
    height: "400px",
    width: "100%",
    borderRadius: "10px",
  };

  const mapOptions = {
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: true,
    zoomControl: true,
  };

  // When location changes, update position and center
  useEffect(() => {
    if (location?.latitude && location?.longitude) {
      const coords = {
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
      };
      setCenter(coords);
      setPosition(coords);
    }
  }, [location]);

  // Handle map load
  const onLoad = useCallback((map) => {
    setMap(map);
    
    // Force map to redraw when loaded in modal
    setTimeout(() => {
      window.google?.maps?.event?.trigger(map, 'resize');
      if (position) {
        map.setCenter(position);
      }
    }, 100);
  }, [position]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  // After map and position are set, ensure proper rendering
  useEffect(() => {
    if (map && position) {
      // Force a resize and recenter when map is visible
      const resizeObserver = new ResizeObserver(() => {
        window.google?.maps?.event?.trigger(map, 'resize');
        map.setCenter(position);
      });

      const mapElement = map.getDiv();
      if (mapElement) {
        resizeObserver.observe(mapElement);
      }

      return () => {
        if (mapElement) {
          resizeObserver.unobserve(mapElement);
        }
      };
    }
  }, [map, position]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps...</div>;

  return (
    <div style={{ width: "100%", height: "400px", position: "relative" }}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={16}
        center={center || { lat: 51.533, lng: -0.098 }}
        options={mapOptions}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {position && (
          <Marker
            position={position}
            visible={true}
            options={{
              optimized: false,
              zIndex: 999
            }}
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default ModalMap;



























// import React, { useState, useCallback, useEffect } from "react";
// import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

// const ModalMap = ({ location }) => {
//   const [map, setMap] = useState(null);
//   const [position, setPosition] = useState(null);
//   const [center, setCenter] = useState(null);

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
//   });

//   const mapContainerStyle = {
//     height: "100%",
//     width: "100%",
//     borderRadius: "10px",
//   };

//   const mapOptions = {
//     mapTypeControl: false,
//     fullscreenControl: false,
//     streetViewControl: true,
//   };

//   const onUnmount = useCallback(() => {
//     setMap(null);
//   }, []);

//   const onLoad = useCallback((map) => {
//     setMap(map);
//   }, []);

//   useEffect(() => {
//     if (location && map) {
//       const coordinates = {
//         lat: Number(location.mlat),
//         lng: Number(location.mlong),
//       };
//       setCenter(coordinates);
//       setPosition(coordinates);
//     }
//   }, [location, map]);

//   if (loadError) {
//     return <div>Error loading maps</div>;
//   }

//   if (!isLoaded) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div style={{ width: "100%", height: "100%", position: "relative" }}>
//       <GoogleMap
//         mapContainerStyle={mapContainerStyle}
//         zoom={16}
//         center={center || { lat: 37.7749, lng: -122.4194 }}
//         options={mapOptions}
//         onLoad={onLoad}
//         onUnmount={onUnmount}
//       >
//         {/* Only render Marker if map is loaded and position is set */}
//         {position && <Marker position={position} />}
//       </GoogleMap>
//     </div>
//   );
// };

// export default ModalMap;
