import React from "react";
import { Bookmark, Clock, Download } from "lucide-react";
import ActionButton from "../GeneralComponents/ActionButton";

const TimeAndSocial = ({ daysPosted, isMobile }) => {
  return isMobile ? (
    <div className="flex flex-col justify-between mt-2">
      <div className="flex items-center">
        <Clock size={12} className="text-gray-700 mr-2" />
        <p className="text-xs text-gray-700">{daysPosted} Day ago</p>
      </div>
      <div className="flex mt-2 space-x-2">
        <div>
          <ActionButton Text="Save" Icon={Bookmark} />
        </div>
        <div className="ml-1">
          <ActionButton Text="Share" Icon={Download} />
        </div>
        <div className="ml-1">
          <button
            onClick={() => {
              window.location.href =
                "https://poggwastmcp.typeform.com/to/Btg6FjxJ"; // Redirect to the URL
            }}
            className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-green-700 text-white hover:bg-green-800 px-4 py-2"
          >
            Make an offer
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <Clock size={12} className="text-gray-700 mr-2" />
        <p className="text-xs text-gray-700">{daysPosted} Day ago</p>
      </div>
      <div className="flex items-center">
        <div>
          <ActionButton Text="Save" Icon={Bookmark} />
        </div>
        <div className="ml-1">
          <ActionButton Text="Share" Icon={Download} />
        </div>
        <div className="ml-1">
          <button
            onClick={() => {
              window.location.href =
                "https://poggwastmcp.typeform.com/to/Btg6FjxJ"; // Redirect to the URL
            }}
            className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-green-700 text-white hover:bg-green-800 px-4 py-2"
          >
            Make an offer
          </button>
        </div>
      </div>
    </div>
  );
};

export default TimeAndSocial;
