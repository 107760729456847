import React from "react";
import { SquareArrowOutUpRight } from "lucide-react";
import ActionButton from "../GeneralComponents/ActionButton";

const AvailabilityAndPlan = ({ propertyInfo }) => {
  const handleFloorplan = (url) => {
    window.open(url);
  };

  return (
    <div className="flex border-black border border-y-1 border-x-0 mt-3">
      <div className="flex flex-col border border-black border-l-0 border-r-1 border-y-0 p-1 w-1/5">
        <span className="text-sm font-medium mb-0.5">
          Available from {propertyInfo.move_in_date}
        </span>
      </div>
      <div
        className="flex flex-col p-1 w-1/3 cursor-pointer"
        onClick={() => handleFloorplan(propertyInfo.floorplan)}
      >
        <img
          src={propertyInfo.floorplan}
          alt={`Property image number 2`}
          className="h-32 w-32 object-cover rounded-lg"
        />
      </div>
    </div>
  );
};

export default AvailabilityAndPlan;
