import React from "react";

const Footer = () => {
  return (
    <div className="flex w-full bg-gray-100 p-4 border-t border-gray-200">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <a
          href="https://www.userea.com/"
          class="text-xs text-gray-600 hover:underline hidden sm:inline-block"
          rel="noopener noreferrer"
        >
          © Rea 2024
        </a>
        <div className="flex gap-4">
          <a class="text-xs text-gray-600 hover:underline" href="/terms">
            Terms
          </a>
          <a class="text-xs text-gray-600 hover:underline" href="/terms">
            Privacy
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
