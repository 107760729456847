import React, { useState } from "react";
import ActionButton from "../GeneralComponents/ActionButton";
import {
  Fence,
  AirVent,
  Container,
  Bath,
  AlarmSmoke,
  CookingPot,
  WashingMachine,
  Cctv,
  Refrigerator,
  Microwave,
  Dog,
  Wifi,
} from "lucide-react";

const PlaceCaracteristics = ({ propertyInfo }) => {
  const [viewCaracteristics, setViewCaracteristics] = useState(false);

  // We should present the caracteristics ourself based on NLP analysis
  const caracteristics = [
    { name: "garden", icon: Fence, label: "Garden" },
    { name: "air_conditioning", icon: AirVent, label: "Air conditioning" },
    { name: "balcony", icon: Container, label: "Balcony" },
    { name: "bathtube", icon: Bath, label: "Bathtube" },
    { name: "smoke_alarm", icon: AlarmSmoke, label: "Smoke alarm" },
    { name: "dish_washer", icon: WashingMachine, label: "Dish washer" },
    { name: "dryer", icon: WashingMachine, label: "Dryer" },
    { name: "gas_cooker", icon: CookingPot, label: "Gas cooker" },
    {
      name: "exterior_security_cameras",
      icon: Cctv,
      label: "Exterior security cameras",
    },
    { name: "fridge", icon: Refrigerator, label: "Fridge" },
    { name: "microwave", icon: Microwave, label: "Microwave" },
    { name: "pets", icon: Dog, label: "Pets" },
    { name: "wifi", icon: Wifi, label: "Wifi" },
  ].filter((caract) => propertyInfo.amenities[caract.name]);

  return (
    <div className="flex flex-col align-top w-full hover:bg-gray-50 justify-start border border-x-0 border-black border-t-0">
      <div className="my-5">
        <h2 className="font-semibold text-2xl mb-2">
          What the place has to offer
        </h2>
        {caracteristics.map((caract) => (
          <div className="flex items-center" key={caract.name}>
            <caract.icon size={14} className="mr-1" />
            <span>{caract.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlaceCaracteristics;
