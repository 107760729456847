import React from "react";
import { ChevronDown } from "lucide-react";

const Question = ({ question, answer, toggleAnswer, expand, isMobile }) => {
  return isMobile ? (
    <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
      <div className="cursor-pointer p-0" onClick={toggleAnswer}>
        <h3 className="flex">
          <button className="flex flex-1 items-start justify-between font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180 text-base text-black px-6 py-4">
            <div className="text-left">{question}</div>
            <ChevronDown
              size={20}
              className={`transition-transform duration-300 ${
                expand ? "transform rotate-180" : ""
              }`}
            />
          </button>
        </h3>
        <div className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
          <div className="pt-0 prose prose-lg text-black">
            {expand && (
              <p className="pt-0 prose prose-lg text-black px-6 pb-4">
                {answer}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
      <div className="cursor-pointer p-0" onClick={toggleAnswer}>
        <h3 className="flex">
          <button className="flex flex-1 align-start justify-between font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180 text-base text-black px-6 py-4">
            {question}
            <ChevronDown
              size={20}
              className={`transition-transform duration-300 ${
                expand ? "transform rotate-180" : ""
              }`}
            />
          </button>
        </h3>
        <div className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
          <div className="pt-0 prose prose-lg text-black">
            {expand && (
              <p className="pt-0 prose prose-lg text-black px-6 pb-4">
                {answer}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;
