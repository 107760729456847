import React from "react";
import { ThumbsDown, Bath, BedDouble } from "lucide-react";
import ActionButton from "../GeneralComponents/ActionButton";

const InfoBox = ({ property = {} }) => {
  // Destructure fields from property with defaults
  const { price = 0, address = "", baths = 0, beds = 0 } = property;

  // Format price with pound symbol and commas
  const formattedPrice = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price);

  return (
    <div className="p-2 bg-white rounded-b-lg flex flex-col h-2/5 justify-between">
      <div className="flex flex-col">
        <div className="flex items-center justify-between space-x-2">
          <span className="text-base font-medium">{formattedPrice} pm</span>
          <div className="flex items-center space-x-1">
            <div className="flex items-center space-x-1">
              <span>{baths}</span>
              <Bath size={15} />
            </div>
            <div className="flex items-center space-x-1">
              <span>{beds}</span>
              <BedDouble size={15} />
            </div>
          </div>
        </div>

        <div>
          <span className="text-xs">{address}</span>
        </div>
      </div>

      <div className="flex items-center mt-3">
        {/* <div>
          <ActionButton Text="" Icon={ThumbsDown} />
        </div> */}
        <div
          className="ml-1"
          onClick={() => {
            window.location.href =
              "https://poggwastmcp.typeform.com/to/Btg6FjxJ";
          }}
        >
          <ActionButton Text="Make an offer" />
        </div>
      </div>
    </div>
  );
};

export default InfoBox;
