import React from "react";
import ModalNavigationHeader from "./ModalHeaderNavigation";
import ModalHeaderClose from "./ModalHeaderClose";

const ModalHeader = ({
  onClose,
  scrollToSection,
  overviewRef,
  picturesRef,
  floorplanRef,
  propertyInfoRef,
  locationRef,
  ratingRef,
  isMobile,
}) => {
  return (
    <div
      className={`flex ${
        isMobile ? "flex-col items-start" : "items-center"
      }  justify-between border-b border-gray-200`}
    >
      <ModalHeaderClose onClose={onClose} />
      <ModalNavigationHeader
        scrollToSection={scrollToSection}
        overviewRef={overviewRef}
        picturesRef={picturesRef}
        floorplanRef={floorplanRef}
        propertyInfoRef={propertyInfoRef}
        locationRef={locationRef}
        ratingRef={ratingRef}
        isMobile={isMobile}
      />
    </div>
  );
};

export default ModalHeader;
