import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Header from "../components/HomePageComponents/Header";
import SkylineImage from "../components/HomePageComponents/SkylineImage";
import Hero from "../components/HomePageComponents/Hero";
import SignUpModal from "../components/GeneralComponents/SignUpModal";
import LoginModal from "../components/GeneralComponents/LoginModal";
import SearchResults from "../components/SearchPageComponents/MainComponents/SearchResults";
import Modal from "../components/SearchPageComponents/MainComponents/Modal"; // Add this import
import FAQ from "../components/HomePageComponents/FAQ";
import Footer from "../components/HomePageComponents/Footer";

const HomePage = () => {
  const [signUp, setSignUp] = useState(false);
  const [login, setLogin] = useState(false);
  const [properties, setProperties] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [criteria, setCriteria] = useState({});
  const [currentQuery, setCurrentQuery] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleSignUpModal = () => {
    setSignUp(!signUp);
  };

  const handleLoginModal = () => {
    setLogin(!login);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 450);
    };

    handleResize(); // Check on initial load
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Clear properties on page reload
  useEffect(() => {
    const handleBeforeUnload = () => {
      setProperties([]);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  const location = useLocation();

  // Clear state on reload and set initial state
  useEffect(() => {
    const handleReload = () => {
      setCurrentQuery("");
    };

    if (performance.navigation.type === 1) {
      handleReload();
    } else if (location.state) {
      setProperties(location.state.properties || []);
      setCriteria(location.state.criteria || {});
      setCurrentQuery(location.state.query || "");
    }
  }, [location.state]); // Added dependency array

  // Modal handlers
  const togglePropertyModal = (property) => {
    setSelectedProperty(property);
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="flex flex-col min-h-screen items-center bg-white">
      <Header
        handleSignUpModal={handleSignUpModal}
        handleLoginModal={handleLoginModal}
      />
      <Hero setProperties={setProperties} isMobile={isMobile} />
      <SignUpModal isOpen={signUp} onClose={handleSignUpModal} />
      <LoginModal isOpen={login} onClose={handleLoginModal} />
      <div className={`flex-grow w-full mx-auto ${isMobile ? "px-4" : "px-8"}`}>
        <SearchResults
          properties={properties}
          togglePropertyModal={togglePropertyModal}
          query={currentQuery}
          criteria={criteria}
          isMobile={isMobile}
        />
      </div>

      {/* Add the Modal component here */}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={togglePropertyModal}
          propertyInfo={selectedProperty}
          isMobile={isMobile}
        />
      )}

      <FAQ isMobile={isMobile} />
      <Footer className="relative sticky bottom-0" />
    </div>
  );
};

export default HomePage;
