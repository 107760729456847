// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD3VHIxshiZZ0L8Uj3qp-RDKoImirv6TWU",
  authDomain: "rea-online-agency.firebaseapp.com",
  projectId: "rea-online-agency",
  storageBucket: "rea-online-agency.firebasestorage.app",
  messagingSenderId: "311211418382",
  appId: "1:311211418382:web:00050ff348f6c6f63aed5f",
  measurementId: "G-PQYDV48B2Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app); // Firestore instance
export { db };



