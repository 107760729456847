import React from "react";

import SearchBarHome from "../SearchBarHome";

const Hero = ({ setProperties, isMobile }) => {
  return isMobile ? (
    <div className="flex flex-col max-w-3xl mt-8 items-center px-4">
      <h1 className="text-5xl font-medium text-black mb-4">
        Discover your next dream home
      </h1>
      <p className="text-lg font-medium text-black max-w-xl mb-12">
        Browse. Apply. Get approved. Move in. No visits needed.
      </p>
    </div>
  ) : (
    <div className="flex flex-col max-w-3xl mt-8 items-center">
      <h1 className="text-5xl font-medium text-black mb-4">
        Discover your next dream home
      </h1>
      <p className="text-lg font-medium text-black max-w-xl mb-12">
        Browse. Apply. Get approved. Move in. No visits needed.
      </p>
    </div>
  );
};

export default Hero;
