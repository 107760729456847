import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { mapStyles } from "../../styles/mapStyles";

const SearchResultMap = ({ properties, selectedProperty, isVisible }) => {
  const [addresses, setAddresses] = useState([]);
  const [map, setMap] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAdTMHEaKj6jX1oerPrh4Qs-sPad49OAHc",//process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const mapContainerStyle = {
    height: "100%",
    borderRadius: "8px",
  };

  const mapOptions = {
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    styles: mapStyles,
  };

  // Default center (London)
  const center = {
    lat: 51.533027837531534,
    lng: -0.0984898974350017,
  };

  // Handle map load
  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  // Handle map unmount
  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  // Function to fit map bounds to markers and extend for zoom-out
  const fitMapToBounds = (mapInstance, markers) => {
    if (markers.length === 0) return;
    
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach((marker) => bounds.extend(marker.position));

    // Increase padding to zoom out more (150px padding around all sides)
    mapInstance.fitBounds(bounds, {
      top: 150,
      bottom: 150,
      left: 150,
      right: 150,
    });
  };

  useEffect(() => {
    if (map && properties && properties.length > 0) {
      const getCoordinates = () => {
        const newAddresses = properties
          .map((property) => {
            if (property.coordinates && 
                property.coordinates.latitude && 
                property.coordinates.longitude) {
              return {
                position: {
                  lat: Number(property.coordinates.latitude),
                  lng: Number(property.coordinates.longitude),
                },
                id: property.id,
              };
            }
            return null;
          })
          .filter(Boolean); // Remove null entries

        setAddresses(newAddresses);

        // Fit the map bounds based on the new addresses and extend bounds for zoom out
        if (newAddresses.length > 0) {
          fitMapToBounds(map, newAddresses);
        }
      };

      getCoordinates();
    }
  }, [map, properties]);

  // Handle loading and error states
  if (!isVisible) return null;
  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps...</div>;
  if (!properties || properties.length === 0)
    return <div>No properties to display</div>;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      mapContainerClassName="w-full h-full rounded-lg"
      zoom={15}
      center={center}
      options={mapOptions}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {addresses.map((marker) => (
        <Marker
          key={marker.id}
          position={marker.position}
          animation={selectedProperty === marker.id ? 2 : null}
        />
      ))}
    </GoogleMap>
  );
};

export default SearchResultMap;















// import React, { useState, useEffect, useCallback } from "react";
// import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
// import { mapStyles } from "../../styles/mapStyles";
// import axios from "axios"; // For making the API requests to geocode the address

// const SearchResultMap = ({ properties, selectedProperty, isVisible }) => {
//   const [addresses, setAddresses] = useState([]);
//   const [map, setMap] = useState(null);

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
//   });

//   const mapContainerStyle = {
//     height: "100%",
//     borderRadius: "8px",
//   };

//   const mapOptions = {
//     mapTypeControl: false,
//     fullscreenControl: false,
//     streetViewControl: false,
//     styles: mapStyles,
//   };

//   const center = {
//     lat: 37.7749,
//     lng: -122.4194, // Default center (San Francisco)
//   };

//   // Handle map load
//   const onLoad = useCallback((map) => {
//     setMap(map);
//   }, []);

//   // Handle map unmount
//   const onUnmount = useCallback(() => {
//     setMap(null);
//   }, []);

//   // Geocode addresses to get lat/lng
//   const geocodeAddress = async (address) => {
//     try {
//       const response = await axios.get(
//         `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
//           address
//         )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
//       );
//       const { lat, lng } = response.data.results[0].geometry.location;

//       // Log the coordinates to check if we're getting them
//       console.log(
//         `Geocoded Address: ${address}, Latitude: ${lat}, Longitude: ${lng}`
//       );

//       return { lat, lng };
//     } catch (error) {
//       console.error(`Error geocoding address: ${address}`, error);
//       return null;
//     }
//   };

//   // Function to fit map bounds to markers and extend for zoom-out
//   const fitMapToBounds = (mapInstance, markers) => {
//     const bounds = new window.google.maps.LatLngBounds();
//     markers.forEach((marker) => bounds.extend(marker.position));

//     // Increase padding to zoom out more (150px padding around all sides)
//     mapInstance.fitBounds(bounds, {
//       top: 150,
//       bottom: 150,
//       left: 150,
//       right: 150,
//     });
//   };

//   useEffect(() => {
//     if (map && properties && properties.length > 0) {
//       const fetchGeocodedAddresses = async () => {
//         const newAddresses = await Promise.all(
//           properties.map(async (property) => {
//             // Parse the address field from JSON string
//             let parsedAddress = {};
//             try {
//               parsedAddress = JSON.parse(property.address);
//             } catch (error) {
//               console.error("Error parsing address JSON:", error);
//               return null;
//             }

//             // Construct the full address string
//             const fullAddress = `${parsedAddress.streetAddress || ""}, ${
//               parsedAddress.city || ""
//             }, ${parsedAddress.state || ""} ${parsedAddress.zipcode || ""}`;

//             if (property.lat && property.lng) {
//               // If lat/lng are already provided, skip geocoding
//               return {
//                 position: { lat: property.lat, lng: property.lng },
//                 id: property.id,
//               };
//             } else {
//               const coordinates = await geocodeAddress(fullAddress); // Geocode the address
//               if (coordinates) {
//                 return {
//                   position: coordinates,
//                   id: property.id,
//                 };
//               }
//             }
//             return null;
//           })
//         );

//         const validAddresses = newAddresses.filter(Boolean); // Remove null entries
//         setAddresses(validAddresses);

//         // Fit the map bounds based on the new addresses and extend bounds for zoom out
//         if (validAddresses.length > 0) {
//           fitMapToBounds(map, validAddresses);
//         }
//       };

//       fetchGeocodedAddresses();
//     }
//   }, [map, properties]);

//   // Handle loading and error states
//   if (!isVisible) return null;
//   if (loadError) return <div>Error loading maps</div>;
//   if (!isLoaded) return <div>Loading maps...</div>;
//   if (!properties || properties.length === 0)
//     return <div>No properties to display</div>;

//   return (
//     <GoogleMap
//       mapContainerStyle={mapContainerStyle}
//       mapContainerClassName="w-full h-full rounded-lg"
//       zoom={12}
//       center={center} // This will be overwritten by `fitBounds` after addresses are fetched
//       options={mapOptions}
//       onLoad={onLoad}
//       onUnmount={onUnmount}
//     >
//       {addresses.map((marker) => (
//         <Marker
//           key={marker.id}
//           position={marker.position}
//           animation={selectedProperty === marker.id ? 2 : null} // Add animation for selected property
//         />
//       ))}
//     </GoogleMap>
//   );
// };

// export default SearchResultMap;
