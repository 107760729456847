import React, { useState } from "react";
import Question from "./Question";

const FAQ = ({ isMobile }) => {
  const [expand, setExpand] = useState([false, false, false, false, false]);

  const toggleAnswer = (index) => {
    setExpand((prevExpand) =>
      prevExpand.map((item, i) => (i === index ? !item : item))
    );
  };

  const QandA = [
    {
      question: "What is REA?",
      answer:
        "REA is a fully online real estate agency designed to make property buying and renting simpler, faster, and more cost-effective. We handle everything online, from browsing listings to completing transactions, so you don’t need to visit properties in person.",
    },
    {
      question: "What makes REA different from traditional real estate agents?",
      answer:
        "Unlike traditional real estate agencies, REA is entirely digital. We streamline the process to save you time, effort, and money. By eliminating the need for in-person visits and paperwork, we offer lower property prices than conventional agents while maintaining a high level of service.",
    },
    {
      question: "How can I arrange a property viewing?",
      answer:
        "With REA, you don’t need to physically visit most of our properties. We provide comprehensive listings, including high-quality images, 3D floor plans, and virtual tours so you can get a full sense of each property from the comfort of your home. However, if you'd still like to arrange an in-person viewing, simply request it through our website, and we’ll be happy to accommodate.",
    },
    {
      question: "Which areas do you cover?",
      answer:
        "Currently, we specialise in properties in the Islington area of London.",
    },
    {
      question: "How do I know if a property is right for me without visiting?",
      answer:
        "We make sure you have everything you need to feel confident in your decision, even without an in-person visit. Our listings feature high-quality photos, detailed descriptions, 3D floor plans, and immersive virtual tours. Plus, our AI assistant is available 24/7 to answer any questions you may have and help you visualize the space.",
    },
  ];

  return isMobile ? (
    <div className="w-full px-4 mx-auto my-8">
      <div className="w-full">
        <h2 className="text-3xl text-black font-semibold text-center mb-6">
          Your questions answered
        </h2>
        <div className="w-full space-y-2">
          {QandA.map((item, i) => (
            <Question
              question={item.question}
              answer={item.answer}
              toggleAnswer={() => toggleAnswer(i)}
              expand={expand[i]}
            />
          ))}
        </div>
      </div>
    </div>
  ) : (
    <div className="w-full max-w-2xl mx-auto my-8">
      <div className="w-full">
        <h2 className="text-3xl text-black font-semibold text-center mb-6">
          Your questions answered
        </h2>
        <div className="w-full space-y-2">
          {QandA.map((item, i) => (
            <Question
              question={item.question}
              answer={item.answer}
              toggleAnswer={() => toggleAnswer(i)}
              expand={expand[i]}
              isMobile={isMobile}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
