import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight, Heart } from "lucide-react";

const PropertyImages = ({ property }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState([]);

  // Extract images from the property
  useEffect(() => {
    if (property && property.pictures && Array.isArray(property.pictures)) {
      // Take first 5 pictures if available
      setImages(property.pictures.slice(0, 5));
    }
  }, [property]);

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };

  const previousImage = () => {
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <div
      className="relative w-full max-w-sm h-60 overflow-hidden" // Fixed size container for uniformity
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Property Image */}
      <div className="relative w-full h-full">
        {images.length > 0 ? (
          <img
            src={images[currentImageIndex]}
            alt={`Property image ${currentImageIndex + 1} of ${property.address}`}
            className="w-full h-full object-cover rounded-t-lg" // Fixed size for the image
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-gray-100 rounded-t-lg">
            <p className="text-gray-500">No images available</p>
          </div>
        )}
      </div>

      {/* Navigation Arrows */}
      {isHovered && images.length > 1 && (
        <>
          <button
            onClick={(e) => {
              e.stopPropagation(); // This limits the click to this button. Therefore, the card can be clickable
              previousImage();
            }}
            className="absolute left-2 top-1/2 -translate-y-1/2 bg-white rounded-full p-1 shadow-md hover:scale-110 transition-transform"
          >
            <ChevronLeft className="w-4 h-4" />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              nextImage();
            }}
            className="absolute right-2 top-1/2 -translate-y-1/2 bg-white rounded-full p-1 shadow-md hover:scale-110 transition-transform"
          >
            <ChevronRight className="w-4 h-4" />
          </button>
        </>
      )}

      {/* Favorite Button */}
      <button className="absolute top-2 right-2 hover:scale-110 transition-transform">
        <Heart className="w-6 h-6 text-gray-100 stroke-2" />
      </button>

      {/* Image Pagination Dots */}
      <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex gap-1">
        {images.map((_, index) => (
          <div
            key={index}
            className={`w-2 h-2 rounded-full ${
              index === currentImageIndex ? "bg-white" : "bg-white/50"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default PropertyImages;